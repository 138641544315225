html {
	height: 100%;
	overflow: hidden;
}

body {
  height: 100%;
	overflow: auto;
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* TODO: remove after design approvement */
  /* scrollbar-width: none;
  -ms-overflow-style: none; 
  overflow-y: hidden; */
  scrollbar-face-color: '#D7D7D7'; /* Firefox 63 compatibility */
  scrollbar-track-color: 'transparent';
  scrollbar-color: '#D7D7D7 transparent';
  scrollbar-width: 'thin';
  scroll-behavior: smooth!important;
}

/* TODO: remove after design approvement */
/* body::-webkit-scrollbar {
  display: none;
} */

body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
/* Track */
body::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
body::-webkit-scrollbar-thumb {
  background: #d7d7d7;
  border-radius: 14px;
}
/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #bacbd9;
}

code {
  font-display: 'auto';
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  pointer-events: none;
}
